<coin-icon-button
  type="secondary"
  icon=""
  svgIcon="ms_teams"
  (click)="msTeamsService.openTeamsChat([employeeProfile.contactInfo.email])"
  [matTooltip]="(hasValidMailAddress ? 'employee-profile.actions.teams' : 'employee-profile.actions.unknown-email-tooltip') | translate"
  [attr.aria-label]="'employee-profile.actions.teams' | translate"
  [disabled]="!hasValidMailAddress">
</coin-icon-button>
<coin-icon-button
  type="secondary"
  icon="email"
  (click)="sendMail()"
  (contextmenu)="hasValidMailAddress ? copyMail($event) : undefined"
  [matTooltip]="
    hasValidMailAddress
      ? ('employee-profile.actions.send-email-tooltip' | translate) + '\n' + ('employee-profile.actions.copy-email-tooltip' | translate)
      : ('employee-profile.actions.unknown-email-tooltip' | translate)
  "
  [attr.aria-label]="'employee-profile.actions.email' | translate"
  [disabled]="!hasValidMailAddress">
</coin-icon-button>
<coin-icon-button
  type="secondary"
  icon="event"
  (click)="scheduleMeeting()"
  [matTooltip]="(hasValidMailAddress ? 'employee-profile.actions.meeting' : 'employee-profile.actions.unknown-email-tooltip') | translate"
  [attr.aria-label]="'employee-profile.actions.meeting' | translate"
  [disabled]="!hasValidMailAddress">
</coin-icon-button>
<coin-icon-button 
  type="secondary"
  icon="save_alt"
  (click)="downloadVcf()"
  [matTooltip]="'employee-profile.actions.download' | translate" [attr.aria-label]="'employee-profile.actions.download' | translate">
</coin-icon-button>
