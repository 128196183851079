import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { LoadingModule } from '@coin/shared/data-access';
import { LegacyComponentsModule } from '@coin/shared/feature-legacy-components';
import { PureFunctionPipe } from '@coin/shared/util-pipes';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { EmployeeProfileComponent } from '@coin/modules/org-tree/feature';
import { V2ButtonComponent, V2CountryFlagComponent } from '@coin/shared/ui-storybook';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { DataTableComponent } from './components/data-table/data-table.component';
import { RowComponent } from './components/data-table/row/row.component';
import { ManagerPreviewComponent } from './components/manager-preview/manager-preview.component';
import { QuickdrawProfileComponent } from './components/quickdraw-profile/quickdraw-profile.component';
import { QuickdrawProfileState } from './components/quickdraw-profile/state/quickdraw-profile.state';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { TruncatedTooltipDirective } from './directives/truncated-tooltip.directive';
import { NamePipe } from './pipes/name.pipe';
import { MaintenanceBannerComponent } from './components/maintenance-banner/maintenance-banner.component';

@NgModule({
  declarations: [
    SubHeaderComponent,
    QuickdrawProfileComponent,
    NamePipe,
    NamePipe,
    DataTableComponent,
    RowComponent,
    ManagerPreviewComponent,
    TruncatedTooltipDirective,
    MaintenanceBannerComponent
  ],
  exports: [
    SubHeaderComponent,
    QuickdrawProfileComponent,
    NamePipe,
    DataTableComponent,
    RowComponent,
    ManagerPreviewComponent,
    TruncatedTooltipDirective,
    MaintenanceBannerComponent
  ],
  imports: [
    LegacyComponentsModule,
    MatIconModule,
    MatTooltipModule,
    FormsModule,
    CommonModule,
    TranslateModule.forChild(),
    RouterModule,
    NgxsModule.forFeature([QuickdrawProfileState]),
    PureFunctionPipe,
    LoadingModule,
    EmployeeProfileComponent,
    V2CountryFlagComponent,
    V2ButtonComponent,
    MatMenu,
    MatMenuTrigger
  ],
  providers: [NamePipe]
})
export class SharedModule {}
