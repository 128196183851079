@if (state.currentOrganisationNode$ | async; as rootNode) {
  @if (rootNode.organisation.parentOrganisationId; as parentOrganisationId) {
    <div class="button-wrapper">
      <button (click)="navigateUp()" [attr.aria-label]="'org-management.navigate-up' | translate" class="no-style arrow-up-button">
        <mat-icon>north</mat-icon>
      </button>
    </div>
  }

  <div class="root" [class.node]="rootNode.children?.length > 0">
    <coin-org-box
      [organisation]="rootNode.organisation"
      [warning]="getWarningMessage(rootNode.organisation, rootNode.children)"
      [selectable]="!readonly"
      [isSelected]="(state.selectedOrganisationId$ | async) === rootNode.organisation.id"
      [selectedEmployeeId]="selectedEmployeeId"
      [showPositionsList]="selectedEmployeeOrganisationId === rootNode.organisation.id"
      [showDirectsCount]="showDirectsCounts"
      [orgBoxActions]="orgBoxActions"
      (selected)="selectOrganisation(rootNode.organisation)"
      (positionSelected)="employeeSelected.emit({ employee: $event.employee, organisation: rootNode.organisation, position: $event })"
      (openJobsClicked)="openJobsSelected.emit($event)"
      (positionListOpened)="positionListOpened(rootNode.organisation)"
      [class.node]="rootNode.children?.length > 0"
      [attr.data-testid]="'org-box-' + rootNode.organisation?.orgCode"></coin-org-box>
  </div>

  <div class="nodes">
    @for (group of rootNode.children | groupOrganisations | keyvalue: disableSort; track trackByTitle($index, $any(group))) {
      <div class="node">
        <coin-org-tree-frame [title]="group.key" class="org-group">
          <ng-container
            actions
            *ngTemplateOutlet="orgGroupActions; context: { $implicit: (state.currentOrganisationNode$ | async)?.organisation, orgType: group.key }"></ng-container>

          <div class="group-container" #groupContainer>
            @for (org of group.value; track trackById($index, org)) {
              <div #parent>
                <coin-org-box
                  [organisation]="org"
                  [isSelected]="(state.selectedOrganisationId$ | async) === org.id"
                  [selectedEmployeeId]="selectedEmployeeId"
                  [hidePositionsList]="!!(state.selectedOrganisationId$ | async)"
                  [showPositionsList]="selectedEmployeeOrganisationId === org.id"
                  [showDirectsCount]="showDirectsCounts"
                  [orgBoxActions]="orgBoxActions"
                  (selected)="selectOrganisation(org, rootNode.organisation)"
                  (positionSelected)="employeeSelected.emit({ employee: $event.employee, organisation: org, position: $event })"
                  (openJobsClicked)="openJobsSelected.emit($event)"
                  (positionListOpened)="positionListOpened(org, true)"
                  [class.has-sub-frame]="(state.selectedOrganisationId$ | async) === org.id && ((state.selectedOrganisationNodeHasChildren$ | async) || roleGroupActions)"
                  class="box"
                  [attr.data-testid]="'org-box-' + org?.orgCode"></coin-org-box>
                <div [class.dot]="(state.selectedOrganisationId$ | async) === org.id && ((state.selectedOrganisationNodeHasChildren$ | async) || roleGroupActions)"></div>
                @if ((state.selectedOrganisationId$ | async) === org.id && ((state.selectedOrganisationNodeHasChildren$ | async) || roleGroupActions)) {
                  <coin-org-tree-sub-frame
                    [title]="org.name"
                    (closeClicked)="closeSubFrame()"
                    class="sub-frame"
                    [style.width]="groupContainer.offsetWidth + 'px'"
                    [style.margin-left]="'-' + (parent.offsetLeft - groupContainer.offsetLeft) + 'px'">
                    @if ((state.selectedOrganisationNode$ | async)?.children; as childNodes) {
                      @for (subGroup of childNodes | groupOrganisations | keyvalue: disableSort; track trackByTitle($index, $any(subGroup))) {
                        <div class="group">
                          <div class="group-header">
                            <h4 class="h2 bold">{{ subGroup.key }}</h4>
                            <ng-container *ngTemplateOutlet="orgGroupActions; context: { $implicit: org, orgType: subGroup.key }"></ng-container>
                          </div>

                          <div class="content">
                            @for (subOrg of subGroup.value; track trackById($index, subOrg)) {
                              <div>
                                <coin-org-box
                                  [organisation]="subOrg"
                                  [selectedEmployeeId]="selectedEmployeeId"
                                  [showPositionsList]="selectedEmployeeOrganisationId === subOrg.id"
                                  [showDirectsCount]="showDirectsCounts"
                                  [orgBoxActions]="orgBoxActions"
                                  (selected)="navigateToOrganisation(subOrg, org)"
                                  (positionSelected)="employeeSelected.emit({ employee: $event.employee, organisation: subOrg, position: $event })"
                                  (openJobsClicked)="openJobsSelected.emit($event)"
                                  (positionListOpened)="positionListOpened(subOrg)"
                                  [attr.data-testid]="'org-box-' + subOrg?.orgCode">
                                </coin-org-box>
                              </div>
                            }
                          </div>
                        </div>
                      }
                    }
                    @if ((state.selectedOrganisationHasBusinessPartnersOrSpecialResponsibilities$ | async) || roleGroupActions) {
                      <div class="sub-groups">
                        @if ((state.selectedOrganisationBusinessPartners$ | async | sortRoles) ?? []; as businessPartners) {
                          @if (businessPartners?.length > 0 || roleGroupActions) {
                            <coin-org-tree-frame [title]="'org-management.business-partners' | translate">
                              <ng-container actions *ngTemplateOutlet="roleGroupActions; context: { $implicit: org, roleType: specialRoleType.BusinessPartner }"></ng-container>

                              <div class="content">
                                @if (businessPartners?.length > 0) {
                                  @for (businessPartner of businessPartners; track trackById($index, businessPartner)) {
                                    <coin-org-tree-special-role-box
                                      [attr.data-testid]="org?.orgCode + '-' + businessPartner.title + '-' + businessPartner.employee?.gid"
                                      [specialRole]="businessPartner"
                                      [specialRoleBoxActions]="specialRoleBoxActions"
                                      [isSelected]="selectedSpecialRoleId === businessPartner.id"
                                      (selected)="specialRoleSelected.emit($event)"></coin-org-tree-special-role-box>
                                  }
                                } @else {
                                  <p>{{ 'org-management.info.no-business-partners-assigned' | translate }}</p>
                                }
                              </div>
                            </coin-org-tree-frame>
                          }
                        }

                        @if ((state.selectedOrganisationSpecialResponsibilities$ | async | sortRoles) ?? []; as specialResponsibilities) {
                          @if (specialResponsibilities?.length > 0 || roleGroupActions) {
                            <coin-org-tree-frame [title]="'org-management.special-responsibilities' | translate">
                              <ng-container
                                actions
                                *ngTemplateOutlet="roleGroupActions; context: { $implicit: org, roleType: specialRoleType.SpecialResponsibility }"></ng-container>

                              <div class="content">
                                @if (specialResponsibilities?.length > 0) {
                                  @for (specialResponsibility of specialResponsibilities; track trackById($index, specialResponsibility)) {
                                    <coin-org-tree-special-role-box
                                      [attr.data-testid]="org?.orgCode + '-' + specialResponsibility.title + '-' + specialResponsibility.employee?.gid"
                                      [specialRole]="specialResponsibility"
                                      [specialRoleBoxActions]="specialRoleBoxActions"
                                      [isSelected]="selectedSpecialRoleId === specialResponsibility.id"
                                      (selected)="specialRoleSelected.emit($event)"></coin-org-tree-special-role-box>
                                  }
                                } @else {
                                  <p>{{ 'org-management.info.no-special-responsibilities-assigned' | translate }}</p>
                                }
                              </div>
                            </coin-org-tree-frame>
                          }
                        }
                      </div>
                    }
                  </coin-org-tree-sub-frame>
                }
              </div>
            }
          </div>
        </coin-org-tree-frame>
      </div>
    }
  </div>

  @if ((state.currentOrganisationHasBusinessPartnersOrSpecialResponsibilities$ | async) || roleGroupActions) {
    <div class="sub-groups">
      @if ((state.currentOrganisationBusinessPartners$ | async | sortRoles) ?? []; as businessPartners) {
        @if (businessPartners?.length > 0 || roleGroupActions) {
          <coin-org-tree-frame [title]="'org-management.business-partners' | translate">
            <ng-container actions *ngTemplateOutlet="roleGroupActions; context: { $implicit: rootNode.organisation, roleType: specialRoleType.BusinessPartner }"></ng-container>

            <div class="content">
              @if (businessPartners?.length > 0) {
                @for (businessPartner of businessPartners; track trackById($index, businessPartner)) {
                  <coin-org-tree-special-role-box
                    [attr.data-testid]="rootNode.organisation?.orgCode + '-' + businessPartner.title + '-' + businessPartner.employee?.gid"
                    [specialRole]="businessPartner"
                    [specialRoleBoxActions]="specialRoleBoxActions"
                    [isSelected]="selectedSpecialRoleId === businessPartner.id"
                    (selected)="specialRoleSelected.emit($event)"></coin-org-tree-special-role-box>
                }
              } @else {
                <p>{{ 'org-management.info.no-business-partners-assigned' | translate }}</p>
              }
            </div>
          </coin-org-tree-frame>
        }
      }

      @if ((state.currentOrganisationSpecialResponsibilities$ | async | sortRoles) ?? []; as specialResponsibilities) {
        @if (specialResponsibilities?.length > 0 || roleGroupActions) {
          <coin-org-tree-frame [title]="'org-management.special-responsibilities' | translate">
            <ng-container
              actions
              *ngTemplateOutlet="roleGroupActions; context: { $implicit: rootNode.organisation, roleType: specialRoleType.SpecialResponsibility }"></ng-container>

            <div class="content">
              @if (specialResponsibilities?.length > 0) {
                @for (specialResponsibility of specialResponsibilities; track trackById($index, specialResponsibility)) {
                  <coin-org-tree-special-role-box
                    [attr.data-testid]="rootNode.organisation?.orgCode + '-' + specialResponsibility.title + '-' + specialResponsibility.employee?.gid"
                    [specialRole]="specialResponsibility"
                    [specialRoleBoxActions]="specialRoleBoxActions"
                    [isSelected]="selectedSpecialRoleId === specialResponsibility.id"
                    (selected)="specialRoleSelected.emit($event)"></coin-org-tree-special-role-box>
                }
              } @else {
                <p>{{ 'org-management.info.no-special-responsibilities-assigned' | translate }}</p>
              }
            </div>
          </coin-org-tree-frame>
        }
      }
    </div>
  }

  @if (rootNode.organisation.updatedAt; as latestUpdate) {
    <div class="timestamp">
      <p class="bold">Latest update:&nbsp;</p>
      <p>{{ latestUpdate | date: 'MM/dd/yyyy HH:mm (zzzz)' }}</p>
    </div>
  }
}
