<div class="general-info" [class.selected]="isSelected">

  <div *ngIf="warning">
    <mat-icon class="warning-icon" matTooltip="{{ warning }}">warning</mat-icon>
  </div>

  @if (orgBoxActions?.length > 0) {
    <button (click)="$event.stopPropagation()" [matMenuTriggerFor]="orgBoxMenu" [attr.aria-label]="'org-management.toggle-org-box-menu' | translate" class="no-style menu-button">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #orgBoxMenu="matMenu">
      @for (menuItem of orgBoxActions; track menuItem) {
        <button
          (click)="menuItem.action(organisation)"
          [disabled]="menuItem.disabled(organisation)"
          mat-menu-item
          class="text"
          matTooltipPosition="right"
          matTooltip="{{ menuItem.disabled(organisation) && menuItem.disabledTooltip ? (menuItem.disabledTooltip | translate) : null }}">
          {{ menuItem.title | translate }}
        </button>
      }
    </mat-menu>
  }

  <button (click)="select()" [disabled]="!selectable" class="no-style org-button" [attr.aria-label]="'org-management.open-organisation-details' | translate">
    <div class="top-info">
      <div class="spacer" [class.more-space]="warning"></div>
      <div class="name">
        <p coinEllipsisTooltip matTooltip>{{ organisation?.orgCode }}</p>
        <p coinEllipsisTooltip matTooltip class="bold">{{ organisation?.name }}</p>
      </div>
      <div class="spacer" [class.more-space]="orgBoxActions?.length > 0"></div>
    </div>
    <div class="middle-info">
      <div class="incumbent">
        @if (!organisation?.heads?.length || organisation.heads.length === 1) {
          <coin-siemens-energy-employee-preview
            [placeholderText]="'org-management.info.no-employee-assigned' | translate"
            [employee]="organisation?.heads?.length ? organisation?.heads[0].employee : undefined"
            [showFlag]="false"
            [boldName]="false"
            [showOrgCode]="false"
            [tiny]="true"
            [namePrefix]="organisation?.heads?.length && organisation.heads[0].isActing ? 'act.' : ''">
          </coin-siemens-energy-employee-preview>
        } @else {
          @if (organisation?.heads?.length > 1) {
            <p coinEllipsisTooltip matTooltip>{{ headsDisplayString }}</p>
          }
        }
      </div>
      @if (showDirectsCount) {
        <p class="direct-reports" matTooltip="{{ 'org-management.direct-reports' | translate }}">
          <mat-icon svgIcon="bee"></mat-icon>{{ organisation?.spanOfControl ?? 0 | number }}
        </p>
      }
    </div>
  </button>

  <div class="bottom-info">
    <button class="left no-style" (click)="togglePositionList()" [attr.aria-label]="'org-management.toggle-employee-list' | translate">
      <div class="content-wrapper">
        <mat-icon matTooltip="{{ 'org-management.persons-info-text' | translate }}">people_alt</mat-icon>
        <p>{{ organisation?.numberOfEmployees | number }} {{ organisation?.totalNumberOfEmployees ? '(' + (organisation.totalNumberOfEmployees | number) + ')' : '' }}</p>
      </div>
      <mat-icon class="arrow-icon" [@toggleIcon]="isPositionsListVisible ? 'open' : 'closed'">keyboard_arrow_down</mat-icon>
    </button>
    @if (organisation?.numberOfOpenJobs > 0) {
      <div class="separator"></div>
    }
    @if (organisation?.numberOfOpenJobs > 0) {
      <button
        class="right no-style"
        (click)="openJobsClicked.emit(organisation)"
        matTooltip="{{ 'org-management.open-jobs-info-text' | translate }}"
        [attr.aria-label]="'org-management.open-jobs-info-text' | translate">
        <mat-icon>work</mat-icon>
        <p>{{ organisation?.numberOfOpenJobs }}</p>
      </button>
    }
  </div>
</div>

@if (isPositionsListVisible) {
  <ul class="position-list">
    @if (positionsLoading$ | async) {
      <div class="loading">
        <app-loading-screen [local]="true" [contrast]="true"></app-loading-screen>
      </div>
    } @else {
      @for (position of positions$ | async; track position) {
        <li class="position" [class.head]="position?.isHead" [class.selected]="position?.employee?.id === selectedEmployeeId">
          <button (click)="positionSelected.emit(position)" [disabled]="!position?.employee" class="no-style" [attr.aria-label]="'org-management.show-employee-info' | translate">
            <coin-siemens-energy-employee-preview
              [placeholderText]="'org-management.info.no-employee-assigned' | translate"
              [employee]="position?.employee"
              [boldName]="false"
              [showOrgCode]="false"
              [tiny]="true"
              [customTopInfo]="position?.isHead ? [position.shortTitle ? position.shortTitle : position.title] : []"></coin-siemens-energy-employee-preview>
          </button>
        </li>
      }
    }
  </ul>
}
