import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { isDevMode, NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LegacyComponentsModule } from '@coin/shared/feature-legacy-components';
import { environment } from '@coin/shared/util-environments';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsModule } from '@ngxs/store';
import { ToastrModule } from 'ngx-toastr';
import { PreventRightClickDirective } from '@coin/shared/util-directives';
import { FeatureFlagDirective } from '@coin/shared/data-management-directives';
import { AuthComponentsModule, AuthGmsAngularClientModule } from '@coin/modules/auth/feature';
import { AuthGmsClientConfig } from '@coin/modules/auth/util';
import { GroupSearchModule } from '@coin/modules/group-search/feature';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IconButtonComponent, UpdateNotifierComponent, V2ButtonComponent } from '@coin/shared/ui-storybook';
import { provideDefaultDateFormats } from '@coin/shared/util-helpers';
import { ApiModule, Configuration, ConfigurationParameters } from './api/generated';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { httpInterceptorProviders } from './http-interceptors';
import { SharedModule } from './shared/shared.module';
import { SocRoutingCompatibilityModule } from './soc-routing-compatibility/soc-routing-compatibility.module';
import { HelpMenuComponent } from './static/header/components/help-menu/help-menu.component';
import { UserMenuState } from './static/header/components/user-menu/state/user-menu.state';
import { UserMenuComponent } from './static/header/components/user-menu/user-menu.component';
import { HeaderComponent } from './static/header/header.component';
import { CollapsedBarComponent } from './static/sidebar/components/collapsed-bar/collapsed-bar.component';
import { SidebarMenuItemComponent } from './static/sidebar/components/sidebar-menu-item/sidebar-menu-item.component';
import { SidebarComponent } from './static/sidebar/sidebar.component';
import { CosmosAppState } from './cosmos-app-state';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, `${environment.cmsContentProdBucketCloudfront}translations/cosmos/`, '.json');
}

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.api.baseUrl
  };
  return new Configuration(params);
}

@NgModule({
  declarations: [AppComponent, HeaderComponent, UserMenuComponent, HelpMenuComponent, SidebarComponent, CollapsedBarComponent, SidebarMenuItemComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    SocRoutingCompatibilityModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    NgxsModule.forRoot(CosmosAppState, {
      developmentMode: !environment.production,
      selectorOptions: { suppressErrors: true }
    }),
    // NgxsReduxDevtoolsPluginModule.forRoot(environment.type !== 'Local' ? { disabled: true } : { disabled: false }),
    // NgxsLoggerPluginModule.forRoot(environment.type !== 'Local' ? { disabled: true } : { disabled: false }),
    NgxsModule.forFeature([UserMenuState]),
    AuthGmsAngularClientModule.forRoot({ ...environment.authentication, ...environment.cosmosAuthentication } as AuthGmsClientConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    SharedModule,
    MatIconModule,
    MatDialogModule,
    BrowserAnimationsModule,
    LegacyComponentsModule,
    ApiModule.forRoot(apiConfigFactory),
    MatBadgeModule,
    GroupSearchModule,
    PreventRightClickDirective,
    FeatureFlagDirective,
    AuthComponentsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWithDelay:5000'
    }),
    UpdateNotifierComponent,
    V2ButtonComponent,
    IconButtonComponent
  ],
  providers: [httpInterceptorProviders, TranslateService, provideHttpClient(withInterceptorsFromDi()), provideDefaultDateFormats()]
})
export class AppModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.loadIcons();
  }

  private loadIcons(): void {
    this.matIconRegistry.addSvgIcon('help_icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/svgs/help.svg'));
    this.matIconRegistry.addSvgIcon('cylinder', this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/svgs/org-management/cylinder.svg'));
    this.matIconRegistry.addSvgIcon('bee', this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/svgs/org-management/bee.svg'));
    this.matIconRegistry.addSvgIcon('co_purple', this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/svgs/co-purple.svg'));
    this.matIconRegistry.addSvgIcon('ms_teams', this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/svgs/ms-teams.svg'));
    this.matIconRegistry.addSvgIcon('org', this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/svgs/org.svg'));
    this.matIconRegistry.addSvgIcon('share', this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/svgs/share.svg'));
    this.matIconRegistry.addSvgIcon('sort', this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/svgs/legal-entities/sort.svg'));
  }
}
