@if (employeeProfile) {
  <div class="header">
    <coin-siemens-energy-employee-preview
      [employee]="employeeProfile.employee"
      [big]="true"
      [showOrgCode]="false"
      [customSubInfo]="currentPositionInfo"></coin-siemens-energy-employee-preview>
    <coin-icon-button type="outline" icon="close" iconSize="large" class="text" (click)="closeProfile.emit()" [attr.aria-label]="'employee-profile.close' | translate" />
  </div>

  <!-- Contact Information -->
  @if (showContactInfo) {
    <coin-org-tree-employee-profile-contact-button-bar [employeeProfile]="employeeProfile"></coin-org-tree-employee-profile-contact-button-bar>

    <div class="contact-info">
      <div class="item">
        <mat-icon>phone</mat-icon>
        @if (employeeProfile.contactInfo.landline; as landline) {
          <a [href]="'tel:' + landline">{{ landline }}</a>
        } @else {
          <p>-</p>
        }
      </div>

      <div class="item">
        <mat-icon>smartphone</mat-icon>
        @if (employeeProfile.contactInfo.mobile; as mobile) {
          <a [href]="'tel:' + mobile">{{ mobile }}</a>
        } @else {
          <p>-</p>
        }
      </div>

      <div class="item">
        <mat-icon>place</mat-icon>
        <p>
          {{ employeeProfile.locationInfo | location }}
        </p>
      </div>

      <div class="item">
        <mat-icon>schedule</mat-icon>
        <p>{{ currentTime$ | async | date: 'HH:mm (zzzz)' : employeeProfile.locationInfo?.timezone ?? defaultTimezone }}</p>
      </div>
    </div>
  }

  <!-- Personal Information -->
  <div class="info-section">
    <h2 class="h4">{{ 'employee-profile.sections.personal.header' | translate }}</h2>

    <div class="item">
      <p>{{ 'employee-profile.sections.personal.gid' | translate }}</p>
      <p copyClick>{{ employeeProfile.employee.gid }}</p>
    </div>

    <div class="item">
      <p>{{ 'employee-profile.sections.personal.are' | translate }}</p>
      @if (goToLegalEntity?.observed) {
        <a (click)="goToLegalEntity.emit({ are: employeeProfile.employee.areCode })">{{ employeeProfile.employee.areCode | uppercase }}</a>
      } @else {
        <p copyClick>{{ employeeProfile.employee.areCode | uppercase }}</p>
      }
    </div>

    <div class="item">
      <p>{{ 'employee-profile.sections.personal.location' | translate }}</p>
      <p copyClick>{{ employeeProfile.locationInfo?.locationOffice }}</p>
    </div>

    <div class="item">
      <p>{{ 'employee-profile.sections.personal.cost-center' | translate }}</p>
      <p copyClick>{{ employeeProfile.employee.costCenter }}</p>
    </div>
  </div>

  <!-- Organisational Information -->
  @if (employeeProfile.employee.orgCode) {
    <div class="info-section">
      <h2 class="h4">{{ 'employee-profile.sections.organisation.header' | translate }}</h2>

      <div class="item">
        <p>{{ 'employee-profile.sections.organisation.name' | translate }}</p>
        <p copyClick>{{ employeeProfile.employee.orgName }}</p>
      </div>

      <div class="item">
        <p>{{ 'employee-profile.sections.organisation.type' | translate }}</p>
        <p copyClick>{{ employeeProfile.employee.orgType }}</p>
      </div>

      <div class="item">
        <p>{{ 'employee-profile.sections.organisation.class' | translate }}</p>
        <p copyClick>{{ employeeProfile.employee.orgClass }}</p>
      </div>

      @if (employeeProfile.employee.orgUnit) {
        <div class="item">
          <p>{{ 'employee-profile.sections.organisation.unit' | translate }}</p>
          <p copyClick>{{ employeeProfile.employee.orgUnit }}</p>
        </div>
      }

      <div class="item">
        <p>{{ 'employee-profile.sections.organisation.code' | translate }}</p>
        @if (goToOrgCode.observed) {
          <a (click)="goToOrgCode.emit({ orgCode: employeeProfile.employee.orgCode })">{{ employeeProfile.employee.orgCode }}</a>
        } @else {
          <p>{{ employeeProfile.employee.orgCode }}</p>
        }
      </div>
    </div>
  }

  <!-- Management Information -->
  @if ((!isReadOnly || employeeProfile.inCompanyManager || employeeProfile.lineManager || employeeProfile.legacyManager) && showManagement) {
    <div class="info-section" [class.editable]="!isReadOnly">
      <div class="management-header">
        <h4>{{ 'employee-profile.sections.management.header' | translate }}</h4>
        <ng-content select="[managementActions]"></ng-content>
      </div>

      @if (employeeProfile.lineManager; as manager) {
        <div class="item person">
          <ng-container
            *ngTemplateOutlet="
              managerTemplate;
              context: {
                manager: manager,
                label: 'employee-profile.sections.management.line-manager',
                tooltip: 'employee-profile.sections.management.line-manager-info' | translate
              }
            "></ng-container>

          @if (showLineManagerFlag) {
            <coin-org-tree-employee-profile-manager-flag [isManagerOverwritten]="employeeProfile.isLineManagerOverwritten"></coin-org-tree-employee-profile-manager-flag>
          }
        </div>
      }

      @if (employeeProfile.inCompanyManager; as manager) {
        <div class="item person">
          <ng-container
            *ngTemplateOutlet="
              managerTemplate;
              context: {
                manager: manager,
                label: 'employee-profile.sections.management.in-company-manager',
                tooltip: 'employee-profile.sections.management.in-company-manager-info' | translate
              }
            "></ng-container>
        </div>
      }

      @if (employeeProfile.legacyManager; as manager) {
        <div class="item person">
          <ng-container
            *ngTemplateOutlet="
              managerTemplate;
              context: {
                manager: manager,
                label: 'employee-profile.sections.management.legacy-manager',
                tooltip: 'employee-profile.sections.management.legacy-manager-info' | translate
              }
            "></ng-container>
        </div>
      }

      <ng-template #managerTemplate let-manager="manager" let-label="label" let-tooltip="tooltip">
        @if (goToOrgCode.observed && manager.paOrgCode) {
          <a (click)="goToOrgCode.emit({ orgCode: manager.paOrgCode, employeeGid: manager.gid, employeeOrgCode: manager.paOrgCode })">
            <ng-container *ngTemplateOutlet="managerEmployeePreview"></ng-container>
          </a>
        } @else {
          <coin-siemens-energy-employee-preview
            [matTooltip]="tooltip"
            [employee]="manager"
            [showOrgCode]="false"
            [noWrap]="true"
            [customSubInfo]="managerDisplay === 'managerType' ? [label | translate, manager.paOrgCode] : [manager.paOrgCode, manager.gid]"
            [showExitDateWarning]="showManagerExitDateWarning"></coin-siemens-energy-employee-preview>
        }

        <ng-template #managerEmployeePreview>
          <coin-siemens-energy-employee-preview
            [matTooltip]="tooltip"
            [employee]="manager"
            [showOrgCode]="false"
            [noWrap]="true"
            [customSubInfo]="managerDisplay === 'managerType' ? [label | translate, manager.paOrgCode] : [manager.paOrgCode, manager.gid]"
            [showExitDateWarning]="showManagerExitDateWarning"></coin-siemens-energy-employee-preview>
        </ng-template>
      </ng-template>
    </div>
  }

  <!-- Additional positions -->
  @if (additionalPositions?.length > 0) {
    <div class="info-section">
      <h2 class="h4">{{ 'employee-profile.sections.positions.header' | translate }}</h2>

      @for (position of additionalPositions; track position) {
        <div class="item">
          <p class="position">
            {{ position.title + (position.isHead ? ' of' : ' in') }}
            @if (goToOrgCode.observed) {
              <a (click)="goToOrgCode.emit({ orgCode: position.orgCode, employeeGid: employeeProfile.employee.gid, employeeOrgCode: position.orgCode })"
                ><ng-container *ngTemplateOutlet="positionOrgCode"></ng-container
              ></a>
            } @else {
              {{ position.orgCode }}
            }
          </p>
          <ng-template #positionOrgCode>{{ position.orgCode }}</ng-template>
        </div>
      }
    </div>
  }
}
