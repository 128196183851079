<header class="header" [class.full-width]="!isSidebarVisible">
  <div class="left">
    <a class="se-logo" [routerLink]="['/']" aria-label="Home Button"></a>
    <ng-container *featureFlag="'displayEnvironment'">
      <div class="environment">
        <mat-icon>{{ currentEnvironment.icon }}</mat-icon>
        <p class="bold">{{ currentEnvironment.type }}</p>
      </div>
    </ng-container>
  </div>
  @if (isSearchVisible$ | async) {
    <div class="search" #searchContainer>
      <coin-icon-button
        (click)="toggleSearch()"
        class="text mobile-search-button"
        [attr.aria-label]="'header.search.open' | translate"
        icon="search"
        type="outline"
      ></coin-icon-button>
      <div class="search-bar-container" [class.mobile-visible]="isSearchBarVisibleOnMobile$ | async" [class.full-width]="!isSidebarVisible">
        <coin-group-search-bar resultCacheKey="recentCosmosSearchResults" [searchResultGroups]="searchResultGroups" class="search-input" #searchBar></coin-group-search-bar>
      </div>
    </div>
  }

  <div class="right">
    @if (updateNotifications.hasUpdates()) {
      <coin-update-notifier></coin-update-notifier>
    }
    <cosmos-user-menu class="user-menu"></cosmos-user-menu>
    <cosmos-help-menu class="help"></cosmos-help-menu>
  </div>
</header>
