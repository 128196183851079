import { CommonModule, KeyValue } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  TrackByFunction
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { GroupOrganisationsPipe, OrganisationNode, OrgBoxMenuItem, SortRolesPipe, SpecialRoleBoxMenuItem } from '@coin/modules/org-tree/util';
import { LoadingModule } from '@coin/shared/data-access';
import { toggleIconAnimation } from '@coin/shared/util-animations';
import { onResize, TinyHelpers } from '@coin/shared/util-helpers';
import { EmployeeSlim, Organisation, Position, SpecialRole, SpecialRoleType } from '@coin/shared/util-models';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { OrgBoxComponent } from '../org-box/org-box.component';
import { OrgTreeFrameComponent } from '../org-tree-frame/org-tree-frame.component';
import { OrgTreeSubFrameComponent } from '../org-tree-sub-frame/org-tree-sub-frame.component';
import { OrgTreeSpecialRoleBoxComponent } from '../special-role-box/special-role-box.component';
import { OrgTreeComponentState } from './org-tree.component.state';

@Component({
  selector: 'coin-org-tree',
  templateUrl: './org-tree.component.html',
  styleUrls: ['./org-tree.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    LoadingModule,
    OrgBoxComponent,
    OrgTreeFrameComponent,
    OrgTreeSubFrameComponent,
    OrgTreeSpecialRoleBoxComponent,
    GroupOrganisationsPipe,
    SortRolesPipe
  ],
  providers: [OrgTreeComponentState],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [toggleIconAnimation]
})
export class OrgTreeComponent implements OnInit, OnChanges {
  @Input({ required: true }) currentOrganisationId: string;
  @Input({ required: true }) organisationNodes: { [organisationId: string]: OrganisationNode };
  @Input() businessPartners: { [organisationId: string]: SpecialRole[] };
  @Input() specialResponsibilities: { [organisationId: string]: SpecialRole[] };
  @Input() positions: { [organisationId: string]: Position[] };
  @Input() positionsLoading: { [organisationId: string]: boolean };
  @Input() selectedEmployeeId: string;
  @Input() selectedSpecialRoleId: string;
  @Input() selectedEmployeeOrganisationId: string;
  @Input() selectedOrganisationId: string;
  @Input() showDirectsCounts = true;
  @Input() readonly = true;
  @Input() orgGroupActions: TemplateRef<{ $implicit: Organisation; orgType: string }>;
  @Input() roleGroupActions: TemplateRef<{ $implicit: Organisation; roleType: SpecialRoleType }>;
  @Input() orgBoxActions: OrgBoxMenuItem[];
  @Input() specialRoleBoxActions: SpecialRoleBoxMenuItem[];
  @Output() navigatedUp = new EventEmitter<Organisation>();
  @Output() employeeSelected = new EventEmitter<{ employee: EmployeeSlim; organisation?: Organisation; position?: Position }>();
  @Output() specialRoleSelected = new EventEmitter<SpecialRole>();
  @Output() openJobsSelected = new EventEmitter<Organisation>();
  @Output() organisationSelected = new EventEmitter<{ organisation: Organisation; parentOrganisation: Organisation }>();
  @Output() subFrameClosed = new EventEmitter<void>();
  @Output() loadPositions = new EventEmitter<Organisation>();

  public specialRoleType = SpecialRoleType;
  public state: OrgTreeComponentState = inject(OrgTreeComponentState);

  private cdr = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);
  private host = inject(ElementRef);
  private toast = inject(ToastrService);
  private translate = inject(TranslateService);

  public ngOnInit(): void {
    this.handleResize();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentOrganisationId) this.state.setCurrentOrganisationId(this.currentOrganisationId);

    if (changes.organisationNodes) this.state.setOrganisationNodes(this.organisationNodes);

    if (changes.businessPartners) this.state.setBusinessPartners(this.businessPartners);

    if (changes.specialResponsibilities) this.state.setSpecialResponsibilities(this.specialResponsibilities);

    if (changes.positions) this.state.setPositions(this.positions);

    if (changes.positionsLoading) this.state.setPositionsLoading(this.positionsLoading);

    if (changes.selectedOrganisationId) this.state.setSelectedOrganisationId(this.selectedOrganisationId);
  }

  public async navigateUp(): Promise<void> {
    const currentNode = await firstValueFrom(this.state.currentOrganisationNode$);
    if (!currentNode.organisation.parentOrganisationId) return;

    const parentNode = this.state.getOrganisationNode(currentNode.organisation.parentOrganisationId);
    this.state.setCurrentOrganisationId(currentNode.organisation.parentOrganisationId);
    this.navigatedUp.emit(parentNode?.organisation);
  }

  public navigateToOrganisation(organisation: Organisation, parentOrganisation: Organisation): void {
    this.state.setCurrentOrganisationId(parentOrganisation.id);
    this.state.setSelectedOrganisationId(organisation.id);
    this.organisationSelected.emit({ organisation, parentOrganisation });
  }

  public selectOrganisation(organisation: Organisation, parentOrganisation?: Organisation): void {
    this.state.setSelectedOrganisationId(organisation.id);
    this.organisationSelected.emit({ organisation, parentOrganisation });
  }

  public closeSubFrame(): void {
    this.state.setSelectedOrganisationId(undefined);
    this.subFrameClosed.emit();
  }

  public async positionListOpened(organisation: Organisation, closeSubFrame: boolean = false): Promise<void> {
    this.loadPositions.emit(organisation);

    if (closeSubFrame) {
      const selectedOrganisationId = await firstValueFrom(this.state.selectedOrganisationId$);
      if (selectedOrganisationId) this.closeSubFrame();
    }
  }

  public disableSort(): number {
    return 0;
  }

  private handleResize(): void {
    onResize(this.host.nativeElement)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.cdr.detectChanges());
  }

  public getWarningMessage(organisation: Organisation, children: Organisation[]): string {
    if (!organisation.parentOrganisationId && !children?.length) {
      return this.translate.instant('org-management.info.no-parent-organisation');
    }
    return;
  }

  public trackByTitle: TrackByFunction<KeyValue<string, { [p: string]: Organisation[] }>> = (index, item) => item.key;

  public trackById = TinyHelpers.trackById;
}
