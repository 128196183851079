import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@coin/shared/util-environments';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { PaginatedResult, Topic } from '@coin/shared/util-models';
import { CommunicationTypeEnum } from '@coin/shared/util-enums';
import { LoadingService } from './loading/loading.service';

@Injectable({
  providedIn: 'root'
})
export class CosmosDashboardService {
  constructor(
    private httpClient: HttpClient,
    private loadingService: LoadingService,
    private toast: ToastrService
  ) {}

  getData(): Observable<Topic[]> {
    this.loadingService.present();
    return this.httpClient.get<Topic[]>(`${environment.api.baseUrl.replace('/cosmosapi', '')}/api/v1/dashboard`).pipe(
      map(topics => {
        return topics.map(topic => {
          return { ...topic, showBanner: topic.type === CommunicationTypeEnum.Banner };
        });
      }),
      tap(() => this.loadingService.dismiss()),
      catchError((res: HttpErrorResponse) => {
        this.loadingService.dismiss();
        this.toast.error(`Error retrieving dashboard data. ${res?.error?.reason || 'Unknown'} `);
        return throwError(`Error retrieving dashboard data. ${res?.error?.reason || 'Unknown'} `);
      })
    );
  }

  getBannerTopics(): Observable<Topic[]> {
    this.loadingService.present();
    return this.httpClient.get<PaginatedResult<Topic>>(`${environment.api.baseUrl.replace('/cosmosapi', '')}/api/v1/dashboard/banners`).pipe(
      map(topics => {
        return topics.content.map(topic => {
          return { ...topic, showBanner: topic.type === CommunicationTypeEnum.Banner };
        });
      }),
      tap(() => this.loadingService.dismiss()),
      catchError((res: HttpErrorResponse) => {
        this.loadingService.dismiss();
        this.toast.error(`Error retrieving dashboard data. ${res?.error?.reason || 'Unknown'} `);
        return throwError(`Error retrieving dashboard data. ${res?.error?.reason || 'Unknown'} `);
      })
    );
  }

  finishActiveTask(taskId: string, employeeId: string): Observable<void> {
    this.loadingService.present();
    return this.httpClient.patch<void>(`${environment.api.baseUrl}/admin/api/v1/master/communications/tasks/${taskId}/${employeeId}/finish`, {}).pipe(
      tap(() => this.loadingService.dismiss()),
      catchError((res: HttpErrorResponse) => {
        this.loadingService.dismiss();
        this.toast.error(`Error finishing task. ${res?.error?.reason || 'Unknown'} `);
        return throwError(`Error finishing task. ${res?.error?.reason || 'Unknown'} `);
      })
    );
  }
}
